import { defineStore } from "pinia";
import { TeamCategoryTypeEnum } from "@sgdf/ui-library";
import type { UserRole } from "./user";

interface UserState {
  meId: string | null;
  teamCategoryId: TeamCategoryTypeEnum;
  dailyTaskViewType: "all" | "me";
  exportParticipantStructureType: "territoire" | "groupe" | "all";
  roleByMeeting: Record<string, UserRole>;
  permissionByMeeting: Record<string, Set<string>>;
  permissionByMeetingLoaded: Record<string, boolean>;
  userPermissions: Set<string>;
  userPermissionsLoaded: boolean;
  isSuperUser: boolean;
  modulesByMeeting: Record<string, Set<string>>;
}

const init = {
  meId: null,
  teamCategoryId: TeamCategoryTypeEnum.DAILY_TASK,
  dailyTaskViewType: "me",
  exportParticipantStructureType: "territoire",
  roleByMeeting: {},
  permissionByMeeting: {},
  permissionByMeetingLoaded: {},
  userPermissions: new Set<string>(),
  userPermissionsLoaded: false,
  isSuperUser: false,
  modulesByMeeting: {},
} as const;

export const useUserStore = defineStore({
  id: "user-store",
  state: (): UserState => {
    return init;
  },
  actions: {
    setTeamCategory(value: TeamCategoryTypeEnum) {
      this.$patch({
        teamCategoryId: value,
      });
    },
    setDailyTaskViewType(value: "all" | "me") {
      this.$patch({
        dailyTaskViewType: value,
      });
    },
    setExportParticipantStructureType(value: "territoire" | "groupe" | "all") {
      this.$patch({
        exportParticipantStructureType: value,
      });
    },
    setRole(role: UserRole, meetingId: string) {
      this.$patch({
        roleByMeeting: {
          ...this.roleByMeeting,
          [meetingId]: role,
        },
      });
    },
    setMeetingPermissions(permissions: Set<string>, meetingId: string) {
      this.$patch({
        permissionByMeeting: {
          ...this.permissionByMeeting,
          [meetingId]: permissions,
        },
      });
      this.$patch({
        permissionByMeetingLoaded: {
          ...this.permissionByMeetingLoaded,
          [meetingId]: true,
        },
      });
    },
    setModules(modules: Set<string>, meetingId: string) {
      this.$patch({
        modulesByMeeting: {
          ...this.modulesByMeeting,
          [meetingId]: modules,
        },
      });
    },
    setUserPermissions(permissions: Set<string>) {
      this.$patch({
        userPermissions: permissions,
      });
      this.$patch({
        userPermissionsLoaded: true,
      });
    },
    setIsSuperUser(value: boolean = true) {
      this.$patch({
        isSuperUser: value,
      });
    },
    setMeId(id: string) {
      this.$patch({
        meId: id,
      });
    },
  },

  getters: {
    getRoleByMeeting: (state) => {
      return (meetingId: string) => state.roleByMeeting[meetingId];
    },
    hasPermission: (state) => {
      return (permission: string, meetingId: string | null = null) => {
        if (state.isSuperUser) {
          return true;
        }

        if (meetingId) {
          return (
            state.permissionByMeeting[meetingId!]?.has(permission) ?? false
          );
        }

        return state.userPermissions.has(permission);
      };
    },
    hasPermissionSync: (state) => {
      return state.userPermissionsLoaded;
    },
    hasMeetingPermissionSync: (state) => {
      return (meetingId: string) => state.permissionByMeetingLoaded[meetingId];
    },
    hasModule: (state) => {
      return (module: string, meetingId: string | null = null) => {
        return (
          meetingId !== null &&
          (state.modulesByMeeting[meetingId]?.has(module) ?? false)
        );
      };
    },
    isSuperUser: (state) => {
      return state.isSuperUser;
    },
  },
});
